<template>
  <v-app>
    <portal-sysbar></portal-sysbar>
    <div class="main-content">
      <portal-aside></portal-aside>
      <portal-title :title="getTitle"></portal-title>
      <div class="content">
        <v-row>
          <v-col lg="8" md="12">
            <v-sheet outlined class="px-5 py-5">
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-sheet class="px-2 py-3" outlined>
                        <v-img
                          :lazy-src="loadImage(getterApp.applicant.photo)"
                          :src="loadImage(getterApp.applicant.photo)"
                          max-width="200"
                          :aspect-ratio="3 / 4"
                          alt="photo"
                        ></v-img>
                      </v-sheet>
                    </v-col>
                    <v-col>
                      <v-sheet class="px-2">
                        <v-card flat>
                          <p class="title">Derniers pays visités</p>
                          <div>
                            <v-chip
                              v-for="state in countries"
                              :key="state"
                              color="primary"
                              class="mx-1 mb-1"
                              label
                              v-show="state"
                            >
                              <v-icon left> mdi-label </v-icon>
                              {{ getCountry(state) }}
                            </v-chip>
                          </div>
                          <p class="title">Commentaire</p>
                          <div>
                            <v-chip
                              label
                              v-show="getterApp.application.comment"
                            >
                              {{ getterApp.application.comment }}
                            </v-chip>
                          </div>
                        </v-card>
                      </v-sheet>
                    </v-col>
                  </v-row>

                  <p class="title">Informations personnelles</p>
                  <v-row>
                    <v-col col>
                      <v-row class="infoU">
                        <v-col col>Nom:</v-col>
                        <v-col col>{{ getterApp.applicant.name }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Prenoms:</v-col>
                        <v-col col>{{ getterApp.applicant.surname }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Sexe:</v-col>
                        <v-col col>{{
                          getGender(getterApp.applicant.gender)
                        }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Situation matr.:</v-col>
                        <v-col col>{{
                          getterApp.applicant.maritalStatus
                        }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Profession:</v-col>
                        <v-col col>{{ getterApp.applicant.job }}</v-col>
                      </v-row>
                    </v-col>
                    <v-col col>
                      <v-row class="infoU">
                        <v-col col>Date de naissance:</v-col>
                        <v-col col>{{
                          format(getterApp.applicant.birthdate)
                        }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Lieu de naissance:</v-col>
                        <v-col col>{{ getterApp.applicant.birthplace }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Pays de naissance:</v-col>
                        <v-col col>{{
                          getterApp.applicant.birthstate.fr
                        }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Nationalité d'orig.:</v-col>
                        <v-col col>{{
                          getterApp.applicant.originNationality.fr
                        }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Nationalité Act.:</v-col>
                        <v-col col>{{
                          getterApp.applicant.actualNationality.fr
                        }}</v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-divider class="my-5"></v-divider>
                  <v-row>
                    <v-col col>
                      <v-row class="infoU">
                        <v-col col>Adresse:</v-col>
                        <v-col col>{{ contact.line }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Ville:</v-col>
                        <v-col col>{{ contact.city }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Code postal:</v-col>
                        <v-col col>{{ contact.cp }}</v-col>
                      </v-row>
                    </v-col>

                    <v-col col>
                      <v-row class="infoU">
                        <v-col col>Pays:</v-col>
                        <v-col col>{{ contact.state.fr }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Telephone:</v-col>
                        <v-col col>{{ contact.phone }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Mail:</v-col>
                        <v-col col>{{ getterApp.applicant.email }}</v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <p class="title mt-5">Passeport - Informations voyage</p>

                  <v-row>
                    <v-col col>
                      <v-row class="infoU">
                        <v-col col>Numero:</v-col>
                        <v-col col>{{ getterApp.passport.number }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Delivré par:</v-col>
                        <v-col col>{{ getterApp.passport.delivryState.fr }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Emis le:</v-col>
                        <v-col col>{{ format(getterApp.passport.delivryDate) }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Expire le:</v-col>
                        <v-col col>{{ format(getterApp.passport.expiredDate) }}</v-col>
                      </v-row>
                    </v-col>
                    <v-col col>
                      <v-row class="infoU">
                        <v-col col>Motif:</v-col>
                        <v-col col>{{ getterApp.journey.reason }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Arrivée:</v-col>
                        <v-col col>{{
                          format(getterApp.journey.entryDate)
                        }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Départ:</v-col>
                        <v-col col>{{
                          format(getterApp.journey.exitDate)
                        }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Justificatif:</v-col>
                        <v-col col>
                          <a
                            href="#"
                            @click="loadFile(getterApp.passport.passport)"
                            class="mr-3"
                            >Passeport</a
                          >
                          <a
                            href="#"
                            @click="loadFile(getterApp.application.ticket)"
                            >Billet</a
                          ></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                  <p class="title mt-5">Adresse a Djibouti</p>
                  <v-row>
                    <v-col col>
                      <v-row class="infoU">
                        <v-col col>Adresse:</v-col>
                        <v-col col>{{ getterApp.journey.stayAddress }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Ville:</v-col>
                        <v-col col>{{ getterApp.journey.stayCity }}</v-col>
                      </v-row>
                    </v-col>
                    <v-col col>
                      <v-row class="infoU">
                        <v-col col>Telephone:</v-col>
                        <v-col col>{{ getterApp.journey.stayPhone }}</v-col>
                      </v-row>
                      <v-row class="infoU">
                        <v-col col>Justificatif:</v-col>
                        <v-col col>
                          <a
                            href="#"
                            class="mr-3"
                            @click="loadFile(getterApp.journey.reservation)"
                            >Lettre d'invitation</a
                          ></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-sheet>
          </v-col>
          <v-col lg="4" md="12">
            <v-sheet class="px-2 py-3" outlined>
              <v-card flat>
                <p class="title">Actions</p>
                <v-row>
                  <v-col v-if="isSupervisor()">
                    <v-btn block class="mb-2" :color="getOpinionColor(getterApp.application.opinion)" depressed>{{
                      getterApp.application.opinion
                    }}</v-btn>
                  </v-col>

                  <v-col v-if="isBasic()">
                    <v-btn-toggle v-model="opinion" borderless>
                      <v-btn value="positive" color="success" class="mr-3">
                        <span class="hidden-sm-and-down">Favorable</span>
                        <v-icon right color="#fff">
                          mdi-check-circle-outline
                        </v-icon>
                      </v-btn>

                      <v-btn value="negatif" color="error" class="mr-3">
                        <span class="hidden-sm-and-down">Defavorable</span>
                        <v-icon right color="#fff">
                          mdi-close-circle-outline
                        </v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      rows="3"
                      hide-details
                      auto-grow
                      outlined
                      :disabled="!isBasic()"
                      v-model="getterApp.application.agentComment"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex justify-end" v-if="isBasic()">
                    <v-btn color="secondary" @click="validate()" class="mr-2"
                      >Confirmer</v-btn
                    >
                    <v-btn color="yellow" @click="delegate()">Deleguer</v-btn>
                  </v-col>
                  <v-col class="d-flex justify-end" v-if="isSupervisor()">
                    <v-btn color="success" @click="resolved(true)" class="mr-2"
                      >Accepter</v-btn
                    >
                    <v-btn color="error" @click="resolved(false)">Refuser</v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-dialog v-model="imgdialog" width="60%">
      <v-sheet color="#fff" class="justify-center d-flex">
        <viewer ref="viewer" :options="options">
          <img :src="imgSrc" />
        </viewer>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="pdfdialog" width="60%">
      <v-sheet color="#fff">
        <div class="justify-center d-flex py-5">
          <v-btn @click="rotate += 90" class="mr-3" color="secondary">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-btn @click="rotate -= 90" class="mr-3" color="secondary">
            <v-icon>mdi-restore</v-icon>
          </v-btn>
          <v-col md="1" class="py-0">
            <v-text-field
              v-model.number="page"
              type="number"
              outlined
              hide-details
              dense
              class="col-1"
            ></v-text-field>
          </v-col>
          /{{ numPages }}
          <v-btn @click="$refs.pdf.print()">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </div>

        <pdf
          ref="pdf"
          :src="src"
          :rotate="rotate"
          :page="page"
          @num-pages="numPages = $event"
          @link-clicked="page = $event"
        ></pdf>
      </v-sheet>
    </v-dialog>
  </v-app>
</template>


<script>
import PortalSysbar from "@/components/PortalSysbar.vue";
import PortalAside from "@/components/PortalAside.vue";
import PortalTitle from "@/components/PortalTitle.vue";
import ApplicationService from "@/services/ApplicationService";
import FileService from "@/services/FileService";
import CountryService from "@/services/CountryService";
import { formatDate, todayDiff } from "@/shared/DateHelper";
import { mapGetters } from "vuex";
import pdf from "vue-pdf";

export default {
  components: {
    PortalSysbar,
    PortalAside,
    PortalTitle,
    pdf,
  },
  data() {
    return {
      countries: [],
      countriesList: [],
      contact: {},
      show: true,
      pdfdialog: false,
      imgdialog: false,
      src: "",
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      rotate: 0,
      imgSrc: "",
      opinion: "standby",
      options: {
        button: true,
        navbar: true,
        toolbar: true,
        tooltip: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
      },
    };
  },
  methods: {
   
    getOpinionColor(opinion) {
      if(opinion.toLowerCase() == "negatif"){
        return "error"
      }

      if(opinion.toLowerCase() == "positive"){
        return "success"
      }
      return "secondary"
    },

    isBasic() {
      let find = this.getterRoles.find((e) => e.authority == "basic");
      if (find !== undefined) {
        return true;
      }
      return false;
    },


    isSupervisor() {
      let find = this.getterRoles.find((e) => e.authority == "supervisor");
      if (find !== undefined) {
        return true;
      }
      return false;
    },

    splitCountries(countries) {
      this.countries = countries.substring(0, countries.length - 1).split(",");
    },

    loadCountries() {
      CountryService.loadCountries().then((response) => {
        this.countriesList = response.data;
      });
    },

    getCountry(id) {
      let find = this.countriesList.find((e) => e.id == id);

      if (find !== undefined) {
        return find.fr;
      }
      return "";
    },

    async getContact() {
      await ApplicationService.getContact(this.getterApp.applicant.id).then(
        (response) => {
          this.contact = response.data;
        }
      );
    },

    validate() {
      this.getterApp.application.opinion = this.opinion;
      this.getterApp.application.opinionDate = new Date();
      ApplicationService.save(this.getterApp.application).then(() => {
        this.$router.push("/application");
      });
    },

    resolved(value){
      this.getterApp.application.status = value;
      this.getterApp.application.statusDate = new Date();
      this.getterApp.application.syscomment = "Supervisor decision"
      this.getterApp.application.solvedBy = this.getterUser.id
      ApplicationService.save(this.getterApp.application).then(() => {
        this.$router.push("/application");
      });
    },

    delegate() {
      this.getterApp.application.opinion = "standby";
      this.getterApp.application.opinionDate = new Date();
      this.getterApp.application.escalad = true;
      ApplicationService.save(this.getterApp.application).then(() => {
        this.$router.push("/application");
      });
    },

    loadImage(id) {
      return process.env.VUE_APP_MSDOC_URL + "/download/" + id;
    },

    loadFile(id) {
      FileService.getExtension(id).then((response) => {
        if (response.data.toLowerCase() == "pdf") {
          this.src = process.env.VUE_APP_MSDOC_URL + "/download/" + id;
          this.pdfdialog = true;
        } else {
          this.imgSrc = this.loadImage(id);
          this.imgdialog = true;
        }
      });
    },

    format(date) {
      return formatDate(date);
    },

    getGender(value) {
      if (value == "M") {
        return "Masculin";
      }
      return "Feminin";
    },

    redirect() {
      if (this.getterApp == null) {
        this.$router.push("/application");
      }
    },

    getPriority(journey) {
      let day = todayDiff(journey.entryDate);
      if (day < 4) {
        return {
          value: "Haute",
          color: "warning",
        };
      }

      if (day < 7) {
        return {
          value: "Moyen",
          color: "yellow",
        };
      }

      return {
        value: "Normal",
        color: "default",
      };
    },
  },
  mounted() {
    this.redirect();
    this.getContact();
    this.loadCountries();
    this.splitCountries(this.getterApp.application.lastVisitedCountries);
  },
  computed: {
    ...mapGetters("app", {
      getterApp: "getApp",
    }),

    ...mapGetters("auth", {
       getterUser: "getUser",
      getterRoles: "getRoles",
    }),

    getTitle() {
      return (
        "Demande n° " +
        this.getterApp.application.trackingNumber +
        ' : (créée le "' +
        this.format(this.getterApp.application.createdAt) +
        '")'
      );
    },
  },
};
</script>

<style scoped>
.infoU .col {
  padding-bottom: 0px;
  padding-top: 0px;
  color: #000;
}

.infoU .col:nth-child(1) {
  font-weight: bolder;
}
</style>